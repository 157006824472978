import 'tinymce/tinymce'
import 'tinymce/themes/silver'
import 'tinymce/icons/default/icons'

import 'tinymce/plugins/image'// 插入上传图片插件
import 'tinymce/plugins/media'// 插入视频插件
import 'tinymce/plugins/table'// 插入表格插件
import 'tinymce/plugins/lists'// 列表插件
import 'tinymce/plugins/wordcount'// 字数统计插件
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/link'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/print'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/anchor'
import 'tinymce/plugins/searchreplace'
import 'tinymce/plugins/visualblocks'
import 'tinymce/plugins/code'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/insertdatetime'
import 'tinymce/plugins/paste'

import 'tinymce/skins/ui/oxide/skin.min.css'
import 'tinymce/skins/ui/oxide/content.inline.min.css'
import * as api from '@/api'

// 编辑器配置
const editConfig = {
  height: 600, // 富文本高度
  language_url: '/tinymce/zh_CN.js', // 中文包
  language: 'zh_CN', // 中文
  skin: false,
  content_css: false,
  // browser_spellcheck: true, // 拼写检查
  branding: false, // 去水印
  elementpath: true, // 禁用编辑器底部的状态栏
  statusbar: true, // 隐藏编辑器底部的状态栏
  paste_data_images: true, // 是否允许粘贴图像
  menubar: true, // 隐藏最上方menu
  fontsize_formats: '14px 16px 18px 20px 24px 26px 28px 30px 32px 36px', // 字体大小
  font_formats: '微软雅黑=Microsoft YaHei,Helvetica Neue;PingFang SC;sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun;serifsans-serif;Terminal=terminal;monaco;Times New Roman=times new roman;times', // 字体
  file_picker_types: 'image', // 允许的值包括：file,image和media。该选项需配合file_picker_types使用，未指定的对话框则不会出现文件上传按钮。file对应链接对话框，image对应图片对话框，media对应多媒体对话框
  images_upload_credentials: true,
  plugins: [ // 插件
    'advlist autolink lists link image charmap print preview anchor',
    'searchreplace visualblocks fullscreen',
    'insertdatetime table paste wordcount'
  ],
  toolbar: 'fontselect fontsizeselect link lineheight forecolor backcolor bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | image quicklink h2 h3 blockquote table numlist bullist preview fullscreen', // 顶部导航
  // 图片上传三个参数，图片数据，成功时的回调函数，失败时的回调函数
  images_upload_handler: function (blobInfo, success, failure) {
    const file = blobInfo.blob()
    api.uploadFile({
      file,
      tag: 'file'
    }).then(res => {
      if (res.data.code === 0) {
        success(res.data.data.url)
      }
    }).catch(err => {
      console.log(err)
      failure(err)
    })
  }
}

export default editConfig
